import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Content from './Content';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Intro from './components/Intro';
import Codegen from './components/Codegen';
import Body from './components/Body';
import Footer from './components/Footer';
import debounce from './debounce';
import './App.css';
// https://www.pluralsight.com/guides/re-render-react-component-on-window-resize

export default () => {
  const list = Content.map(c => { return {url: '/' + c.url, title: c.title} });
  const [dimensions, setDimensions] = React.useState({ height: window.innerHeight, width: window.innerWidth });
  const [showSidebar, setShowSidebar] = useState(dimensions.width>=768);
  useEffect(() => {
    const debouncedHandleResize = debounce(() => setDimensions({ height: window.innerHeight, width: window.innerWidth}), 100);
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  });
  useEffect(() => console.log("❤️ Zhen and Aiden ..."), []);
  const rendPage = () => {
    const curr = list.map(l => l.url).indexOf(window.location.pathname);
    if (curr === -1) { return ( <span style={{color: 'red'}}>Wrong url, return <a href="/" style={{color: 'blue'}}>home</a></span> ); }
    const prev = (curr === 0) ? null : list[curr - 1];
    const next = (curr === list.length-1) ? null : list[curr + 1];
    return(
      <React.Fragment>
        <Sidebar list={list} width={dimensions.width} showSidebar={showSidebar} />
        <div id="content">
          <Header title={Content[curr].title} width={dimensions.width} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
          <Body example={Content[curr]} />
        </div>
        <Footer prev={prev} next={next} width={dimensions.width} />
      </React.Fragment>
      );}
  return (
    <div className="App">
      <Router>
      <Route exact path='/' render={() => 
            <React.Fragment>
            <div id="content">
              <Header title={'Learn SystemC with Examples'} width={0} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
              <Intro list={list}/>
            </div>
            <Footer prev={null} next={list[0]} width={dimensions.width} />
        </React.Fragment>}/>
        <Route exact path='/codegen' render={() => 
            <React.Fragment>
            <Sidebar list={list} width={dimensions.width} showSidebar={showSidebar} />
            <div id="content">
              <Header title={'SystemC/C++ Code and UML Diagram Generator'} width={dimensions.width} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
              <Codegen />
            </div>
            <Footer prev={null} next={list[0]} width={dimensions.width} />
        </React.Fragment>}/>
        <Route exact path='/basic/hello_world' render={rendPage}/>
        <Route exact path='/basic/module' render={rendPage}/>
        <Route exact path='/basic/sc_ctor' render={rendPage}/>
        <Route exact path='/basic/sc_has_process' render={rendPage}/>
        <Route exact path='/basic/simu_process' render={rendPage}/>
        <Route exact path='/basic/simu_stage' render={rendPage}/>
        <Route exact path='/basic/time' render={rendPage}/>
        <Route exact path='/basic/concurrency' render={rendPage}/>
        <Route exact path='/basic/event' render={rendPage}/>
        <Route exact path='/basic/event_combined' render={rendPage}/>
        <Route exact path='/basic/delta_cycle' render={rendPage}/>
        <Route exact path='/basic/sensitivity' render={rendPage}/>
        <Route exact path='/basic/initialization' render={rendPage}/>
        <Route exact path='/basic/method' render={rendPage}/>
        <Route exact path='/basic/event_queue' render={rendPage}/>
        <Route exact path='/basic/event_queue_combined' render={rendPage}/>
        <Route exact path='/basic/channel_mutex' render={rendPage}/>
        <Route exact path='/basic/channel_semaphore' render={rendPage}/>
        <Route exact path='/basic/channel_fifo' render={rendPage}/>
        <Route exact path='/basic/signal_readwrite' render={rendPage}/>
        <Route exact path='/basic/signal_event' render={rendPage}/>
        <Route exact path='/basic/signal_many_writer' render={rendPage}/>
        <Route exact path='/basic/resolved_signal' render={rendPage}/>
        <Route exact path='/basic/signal_bool' render={rendPage}/>
        <Route exact path='/basic/buffer' render={rendPage}/>
        <Route exact path='/basic/port' render={rendPage}/>
        <Route exact path='/basic/export' render={rendPage}/>
        <Route exact path='/basic/port2port' render={rendPage}/>
        <Route exact path='/basic/specialized_port' render={rendPage}/>
        <Route exact path='/basic/port_array' render={rendPage}/>
        <Route exact path='/basic/primitive_channel' render={rendPage}/>
        <Route exact path='/basic/hierarchical_channel' render={rendPage}/>
        <Route exact path='/basic/trace' render={rendPage}/>
        <Route exact path='/basic/report' render={rendPage}/>
        <Route exact path='/basic/customized_datatype' render={rendPage}/>
        <Route exact path='/basic/clock' render={rendPage}/>
        <Route exact path='/basic/cthread' render={rendPage}/>
        <Route exact path='/pattern/trigger_when_busy' render={rendPage}/>
        <Route exact path='/pattern/trigger_when_busy2' render={rendPage}/>
        <Route exact path='/pattern/interrupt_when_busy' render={rendPage}/>
        <Route exact path='/pattern/interrupt_when_busy2' render={rendPage}/>
        <Route exact path='/setup/docker' render={rendPage}/>
        {/* <Route exact path='/:level1/:level2' key='/:level1/:level2' render={rendPage}/> */}
      </Router>
    </div>
  );
};
