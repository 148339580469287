import React, { useState, useEffect, useRef } from 'react';
import './Alert.css';

export default () => {
  const [show, setShow] = useState(true);
  const adRef1 = useRef(null);
  const adRef2 = useRef(null);
  const adRef3 = useRef(null);
  const adRef4 = useRef(null);
  const adRef5 = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9866735983376762';
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
    script.onload = () => {
      try {
        if (window.adsbygoogle) {
          window.adsbygoogle.push({});
          window.adsbygoogle.push({});
          window.adsbygoogle.push({});
          window.adsbygoogle.push({});
          window.adsbygoogle.push({});
        }
      } catch (e) {
        console.error('AdSense script failed to load', e);
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    show &&
    <div className="alert">
      <span className="closebtn" onClick={() => setShow(false)}>&times;</span>
      {/* <div>A top 5 worldwide smartphone manufacturer is hiring talents to develop 4G/5G modem chipset. Multiple openings in California, USA. <a href="mailto:learnwithexamples@outlook.com" className="email contactme">Contact Me</a> if you are interested.</div> */}
      <div>
      <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
      <ins className="adsbygoogle"
            style={{ display: 'block', width: '23%', margin: '1%', height: '280' }}
            data-ad-client="ca-pub-9866735983376762"
            data-ad-slot="4137853880"
            data-ad-format="auto"
            data-full-width-responsive="true"
            ref={adRef1}></ins>
        <ins className="adsbygoogle"
            style={{ display: 'block', width: '23%', margin: '1%', height: '280' }}
            data-ad-client="ca-pub-9866735983376762"
            data-ad-slot="5115328985"
            data-ad-format="auto"
            data-full-width-responsive="true"
            ref={adRef2}></ins>
        <ins className="adsbygoogle"
            style={{ display: 'block', width: '23%', margin: '1%', height: '280' }}
            data-ad-client="ca-pub-9866735983376762"
            data-ad-slot="3843437897"
            data-ad-format="auto"
            data-full-width-responsive="true"
            ref={adRef3}></ins>
        <ins className="adsbygoogle"
            style={{ display: 'block', width: '23%', margin: '1%', height: '280' }}
            data-ad-client="ca-pub-9866735983376762"
            data-ad-slot="4968817306"
            data-ad-format="auto"
            data-full-width-responsive="true"
            ref={adRef4}></ins>
            </div>
      </div>
    </div>
  );
}
